import { createApp } from "vue";
import {
	Toast,
	CellGroup,
	Cell,
	Tabbar,
	TabbarItem,
	Button,
	Tag,
	Tab,
	Tabs,
	List,
	Form,
	Field,
	Popup,
	DropdownMenu,
	DropdownItem,
	Col,
	CheckboxGroup,
	Checkbox,
	Row,
	RadioGroup,
	Radio,
	Dialog,
	Icon,
	Image,
	Space,
} from "vant";
import App from "./App.vue";
import router from "./router";
import "vant/lib/index.css";
import h5ui from "./h5ui";

const app = createApp(App);

app.use(router);
app.use(Toast);
app.use(CellGroup);
app.use(Cell);
app.use(Tabbar);
app.use(Button);
app.use(TabbarItem);
app.use(Tag);
app.use(Tab);
app.use(Tabs);
app.use(List);
app.use(Form);
app.use(Field);
app.use(Popup);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Col);
app.use(Row);
app.use(RadioGroup);
app.use(CheckboxGroup);
app.use(Checkbox);
app.use(Radio);
app.use(Dialog);
app.use(Icon);
app.use(Image);
app.use(Space);

app.use(h5ui);
app.mount("#app");
