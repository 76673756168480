import http from "@/utils/request";

export default {
	lists: (params) => {
		const url = `/scales`;
		return http.get(url, params);
	},
	detail: (id) => {
		const url = `/scales/${id}`;
		return http.get(url);
	},
	save: (data) => {
		const url = `/scales/subjects`;
		return http.post(url, data);
	},
};
