import http from "@/utils/request";

export default {
	schoolInfo: (host) => {
		return http.get(`/schools/${host}`);
	},
	bind: function (data) {
		const url = `/login`;
		return http.patch(url, data);
	},
	logout: function () {
		const url = "/logout";
		return http.patch(url);
	},
	password: function (data) {
		const url = "/password";
		return http.patch(url, data);
	},
};
