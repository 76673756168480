import http from "@/utils/request";

export default {
	lists: (params) => {
		const url = `/tasks`;
		return http.get(url, params);
	},
	detail: (id) => {
		const url = `/tasks/${id}`;
		return http.get(url);
	},
};
