import { createRouter, createWebHistory } from "vue-router";
import tool from "../utils/tool";
import config from "../config";

const routes = [
	{
		path: "/",
		name: "首页",
		component: () => import("@/components/home"),
	},
	{
		path: "/login",
		name: "登录",
		component: () => import("@/components/Login"),
	},
	{
		path: "/my",
		name: "个人中心",
		component: () => import("@/components/my/index"),
	},
	{
		path: "/my/password",
		name: "修改密码",
		component: () => import("@/components/my/password"),
	},
	{
		path: "/tasks",
		name: "我的测评",
		component: () => import("@/components/tasks"),
	},
	{
		path: "/tasks/:id",
		name: "测评任务",
		component: () => import("@/components/tasks/detail"),
	},
	{
		path: "/scales/:taskId/:id",
		name: "测评",
		component: () => import("@/components/scales/detail"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const hasLogin =
		tool.data.get(config["tokenField"]) && tool.data.get(config["userField"]);
	// 已登录
	if (!hasLogin) {
		if (to.path != "/login") {
			tool.data.remove(config["tokenField"]);
			tool.data.remove(config["userField"]);
			const toPath = to.fullPath || to.path || "/";
			return router.push("/login?to=" + toPath);
		}
	} else {
		if (to.path == "/login") {
			return router.push(config["homePage"] || "/");
		}
	}
	document.title = to.name || "";
	return next();
});

export default router;
