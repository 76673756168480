import config from "./config";
import api from "./api";
import tool from "./utils/tool";
import http from "./utils/request";

export default {
  install(app) {
    //挂载全局对象
    app.config.globalProperties.$CONFIG = config;
    app.config.globalProperties.$TOOL = tool;
    app.config.globalProperties.$HTTP = http;
    app.config.globalProperties.$API = api;
  },
};
